/**
 Copyright SaasTec Labs - 2024. Todos os direitos reservados
 -----
 Arquivo: colunas.js
 Projeto: SaaSERP2
 Criação do arquivo: 07/02/2022 - 12:34:03
 Autor: SaaSTec Labs
 -----
 Última modificação: 30/05/2022 - 15:45:28
 Modificado por: pedro.rosa
*/
module.exports = {
  opcoesTipo: [
    {
      value: "CE048_NM_VENDED",
      text: "Colaborador",
      type: ["text"],
    },
    {
      value: "CE045_NM_FANTAS",
      text: "Loja",
      type: ["text"],
    },
    {
      value: "CE048_CD_SITUAC",
      text: "Situação",
      type: ["text"],
    },
    {
      value: "CE048_VL_FIXO",
      text: "Salário fixo",
      type: ["number"],
    },
    {
      value: "CE048_PC_COMISS",
      text: "% comissão",
      type: ["number"],
    },
  ],
  itensFerramentas: [
    {
      text: "Nome loja",
      value: {
        type: "textfield",
        SP: false,
        opcoesTipo: false,
        nome: "nomeLoja",
        adicionar: false,
        filtro: true,
        itens: false,
        objKey: "ce048_nm_fantas",
      },
    },
    {
      text: "Situação",
      value: {
        type: "autocomplete",
        SP: null,
        opcoesTipo: null,
        nome: "situac",
        adicionar: false,
        filtro: true,
        itens: [
          { text: "Ativo", value: 1 },
          { text: "Inativo", value: 2 },
        ],
        objKey: "ce048_cd_situac",
      },
    },
    {
      text: "Nome Colaborador",
      value: {
        type: "textfield",
        SP: false,
        opcoesTipo: false,
        nome: "nomeVended",
        adicionar: false,
        filtro: true,
        itens: false,
        objKey: "ce048_nm_vended",
      },
    },
    {
      text: "Salário fixo",
      value: {
        type: "textfield",
        SP: false,
        opcoesTipo: false,
        nome: "vlFixo",
        adicionar: false,
        filtro: false,
        itens: false,
        objKey: "ce048_pc_comiss",
      },
    },
    {
      text: "% Comissão",
      value: {
        type: "textfield",
        SP: false,
        opcoesTipo: false,
        nome: "pcComiss",
        adicionar: false,
        filtro: false,
        itens: false,
        objKey: "ce048_pc_comiss",
      },
    },
  ],
  tabelaImportada: [
    {
      cabecalho: "Cód. colaborador",
      larg: "2.5*",
      valor: "CodColaborador",
      align: "right",
    },
    {
      cabecalho: "Colaborador",
      larg: "2.5*",
      valor: "Colaborador",
      align: "left",
    },
    {
      cabecalho: "Loja",
      larg: "2.5*",
      valor: "Loja",
      align: "right",
    },
    {
      cabecalho: "Cargo",
      larg: "2.5*",
      valor: "Cargo",
      align: "left",
    },
    {
      cabecalho: "Data de admissão",
      larg: "2.5*",
      valor: "DtAdmissão",
      align: "left",
    },
    {
      cabecalho: "CEP",
      larg: "2.5*",
      valor: "CEP",
      align: "right",
    },
    {
      cabecalho: "Número endereço",
      larg: "2.5*",
      valor: "NrEndereço",
      align: "right",
    },
    {
      cabecalho: "Salário",
      larg: "2.5*",
      valor: "Salario",
      align: "right",
    },
    {
      cabecalho: "Comissão(%)",
      larg: "2.5*",
      valor: "Comissao",
      align: "right",
    },
  ],
  colunas: [
    {
      cabecalho: "Colaborador",
      larg: "1*",
      valor: "ce048_nm_vended",
      align: "left",
      minWidth: 20,
    },
    {
      cabecalho: "Loja",
      larg: "1*",
      valor: "ce045_nm_fantas",
      align: "left",
      minWidth: 20,
    },
    {
      cabecalho: "Situação",
      larg: "0.5*",
      valor: "ce048_cd_situac_mdl",
      align: "right",
      minWidth: 20,
    },
    {
      cabecalho: "% Comissão",
      larg: "1*",
      valor: "ce048_pc_comiss_mdl",
      align: "right",
      minWidth: 20,
    },
  ],
  importsObrigatorios: [
    "CodColaborador",
    "Colaborador",
    "NrLoja",
    "Cargo",
    "DtAdmissão",
    "CEP",
    "NrEndereço",
    "Salario",
    "Comissao",
  ],
  campos: [
    {
      DIVISION: "",
      fields: [
        {
          model: "",
          vuetify: "textfield",
          type: "text",
          nome: "Cód. colaborador",
          hint: "",
          cols: 4,
          maxLength: 25,
          required: true,
          rules: [(n) => !!n || "Campo obrigatório"],
          chave: "ce048_cd_vendee",
        },
        {
          model: "",
          vuetify: "textfield",
          type: "text",
          nome: "Nome Colaborador",
          hint: "",
          cols: 8,
          required: true,
          maxLength: 40,
          rules: [(n) => !!n || "Campo obrigatório"],
          chave: "ce048_nm_vended",
        },
        {
          model: "",
          vuetify: "textfield",
          type: "text",
          nome: "CPF/CNPJ",
          hint: "",
          cols: 6,
          maxLength: 18,
          mask: ["###.###.###-##", "##.###.###/####-##"],
          chave: "ce048_nr_cpfcnpj",
        },
        {
          model: "",
          vuetify: "textfield",
          type: "text",
          nome: "RG",
          hint: "",
          cols: 6,
          maxLength: 20,
          chave: "ce048_nr_rg",
        },
        {
          model: "",
          vuetify: "searchtype",
          type: "text",
          nome: "Loja",
          hint: "",
          cols: 6,
          required: true,
          items: [],
          filtro: false,
          sp: "SP_CE360905003",
          opcoesTipo: [
            {
              value: "CE045_NR_LOJA",
              text: "Nr. loja",
              type: ["text"],
            },
            {
              value: "CE045_NM_FANTAS",
              text: "Nm. fantasia",
              type: ["text"],
            },
          ],
          retorno: [],
          chave: "ce045_nm_fantas",
          maxLength: 40,
        },
        {
          model: "",
          vuetify: "autocomplete",
          type: "text",
          nome: "Situação",
          hint: "",
          cols: 3,
          required: true,
          items: [
            { text: "Ativo", value: 1 },
            { text: "Inativo", value: 2 },
          ],
          chave: "ce048_cd_situac",
        },
        {
          model: "",
          vuetify: "autocomplete",
          type: "text",
          nome: "É Colaborador?",
          hint: "",
          cols: 3,
          required: true,
          items: [
            { text: "Sim", value: 1 },
            { text: "Não", value: 2 },
          ],
          chave: "ce048_id_vended",
          maxLength: 3,
        },
        {
          model: "",
          vuetify: "datepicker",
          type: "text",
          nome: "Data de admissão",
          hint: "",
          cols: 3,
          required: true,
          items: [
            { text: "Sim", value: 1 },
            { text: "Não", value: 2 },
          ],
          chave: "ce048_dt_incemp",
          maxLength: 8,
        },
        {
          model: "",
          vuetify: "textfield",
          type: "text",
          nome: "Cargo",
          hint: "",
          cols: 5,
          required: true,
          chave: "ce048_nm_cargo",
          maxLength: 40,
        },
        {
          model: "",
          vuetify: "textfield",
          type: "text",
          nome: "CEP",
          hint: "",
          cols: 4,
          required: true,
          chave: "ce048_nr_cep",
          maxLength: 10,
          mask: "##.###-###",
        },
        {
          model: "",
          vuetify: "textfield",
          type: "text",
          nome: "Endereço",
          hint: "",
          cols: 6,
          required: true,
          chave: "ce048_nm_end",
          maxLength: 40,
        },
        {
          model: "",
          vuetify: "textfield",
          type: "text",
          nome: "Número",
          hint: "",
          cols: 2,
          required: true,
          chave: "ce048_nr_end",
          maxLength: 7,
        },
        {
          model: "",
          vuetify: "textfield",
          type: "text",
          nome: "Bairro",
          hint: "",
          cols: 4,
          required: true,
          maxLength: 30,
          chave: "ce048_nm_bairro",
        },
        {
          model: "",
          vuetify: "textfield",
          type: "text",
          nome: "Cidade",
          hint: "",
          cols: 4,
          required: true,
          chave: "ce048_nm_cidade",
          maxLength: 40,
        },
        {
          model: "",
          vuetify: "autocomplete",
          type: "text",
          nome: "Estado",
          hint: "",
          cols: 2,
          required: true,
          chave: "ce048_sg_estado",
          items: [
            "AC",
            "AL",
            "AP",
            "AM",
            "BA",
            "CE",
            "ES",
            "GO",
            "MA",
            "MT",
            "MS",
            "MG",
            "PA",
            "PB",
            "PR",
            "PE",
            "PI",
            "RJ",
            "RS",
            "RO",
            "RR",
            "SC",
            "SP",
            "SE",
            "TO",
            "DF",
          ],
        },
        {
          model: "",
          vuetify: "textfield",
          type: "text",
          nome: "Complemento",
          hint: "",
          cols: 6,
          required: false,
          maxLength: 20,
          chave: "ce048_ds_compl",
        },
        {
          model: "",
          vuetify: "textfield",
          type: "text",
          nome: "DDD",
          hint: "",
          cols: 2,
          required: true,
          chave: "ce048_nr_dddtel",
          maxLength: 2,
        },
        {
          model: "",
          vuetify: "textfield",
          type: "text",
          nome: "Telefone",
          hint: "",
          cols: 10,
          required: true,
          chave: "ce048_nr_tel",
          maxLength: 10,
          mask: "#####-####",
        },
        {
          model: "",
          vuetify: "textfield",
          type: "text",
          nome: "Cód. departamento",
          hint: "",
          cols: 6,
          chave: "ce048_cd_depto",
          maxLength: 8,
        },
        {
          model: "",
          vuetify: "textfield",
          type: "text",
          nome: "Cód. cargo",
          hint: "",
          cols: 6,
          chave: "ce048_cd_cargo",
          maxLength: 8,
        },
        {
          model: "",
          vuetify: "textfield",
          type: "text",
          nome: "Cód. banco",
          hint: "",
          cols: 4,
          required: false,
          chave: "ce048_cd_banco",
          maxLength: 5,
        },
        {
          model: "",
          vuetify: "textfield",
          type: "text",
          nome: "Cód. agência",
          hint: "",
          cols: 4,
          required: false,
          chave: "ce048_cd_agenc",
          maxLength: 5,
        },
        {
          model: "",
          vuetify: "textfield",
          type: "text",
          nome: "Cód. conta corrente",
          hint: "",
          cols: 4,
          required: false,
          chave: "ce048_cd_cntcor",
          maxLength: 12,
        },
        {
          model: "",
          vuetify: "textfield",
          type: "tel",
          nome: "Salário fixo",
          hint: "",
          cols: 8,
          money: {
            decimal: ",",
            thousands: "",
            prefix: "R$ ",
            suffix: "",
            precision: 2,
          },
          required: true,
          rules: [(n) => !!n || "Campo obrigatório"],
          chave: "ce048_vl_fixo",
          maxLength: 17,
        },
        {
          model: "",
          vuetify: "textfield",
          type: "tel",
          nome: "(%) comissão",
          hint: "",
          cols: 4,
          money: {
            decimal: ",",
            thousands: "",
            prefix: "",
            suffix: "%",
            precision: 2,
          },
          required: true,
          rules: [(n) => !!n || "Campo obrigatório"],
          chave: "ce048_pc_comiss",
          maxLength: 17,
        },
      ],
    },
  ],
};
