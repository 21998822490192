/**
 Copyright SaasTec Labs - 2024. Todos os direitos reservados
 -----
 Arquivo: script.js
 Projeto: SaaSERP2
 Criação do arquivo: 07/02/2022 - 12:34:03
 Autor: SaaSTec Labs
 -----
 Última modificação: 30/05/2022 - 15:45:35
 Modificado por: pedro.rosa
*/
import ferramentas from "@/components/ferramentas";
import botoesAcao from "@/components/botoesAcao";
import moment from "moment";
import { tabelaCadastroColaboradores } from "./table";
import {
  opcoesTipo,
  itensFerramentas,
  colunas,
  importsObrigatorios,
  campos,
  tabelaImportada,
} from "./colunas";
export default {
  components: { ferramentas, botoesAcao },
  data() {
    return {
      opcoesTipo,
      itensFerramentas,
      colunas,
      importsObrigatorios,
      tabelaImportada,
      campos,
      arquivo: tabelaCadastroColaboradores,
      nomeArquivo: "SaaS - Template cadastro de colaboradores.xlsx",
      flexgrid: {},
      abaEscolhida: "",
      keyTabelaWijmo: 0,
      dados: [],
      abas: [],
    };
  },
  computed: {
    campoLoja() {
      return this.campos[0].fields[4];
    },
  },
  watch: {
    "campoLoja.retorno": {
      handler() {
        this.campoLoja.model = this.campoLoja.retorno.ce045_nm_fantas;
      },
    },
  },
  methods: {
    async criaObj(item) {
      let usuario = await this.getUser();
      let data = item.ce048_dt_incemp.split("/");
      let ent = {
        _rCD_VENDED: item.ce048_cd_vended ? item.ce048_cd_vended : null, // null insere, not null edita
        _rCD_VENDEE: item.ce048_cd_vendee ? item.ce048_cd_vendee : null,
        _rNM_VENDED: item.ce048_nm_vended,
        _rNR_LOJA: item.ce045_nr_loja,
        _rCD_SITUAC: item.ce048_cd_situac,
        _rID_VENDED: item.ce048_id_vended,
        _rDT_INCEMP: `${data[2]}${data[1]}${data[0]}`,
        _rNM_CARGO: item.ce048_nm_cargo,
        _rNR_CEP: item.ce048_nr_cep,
        _rNM_END: item.ce048_nm_end,
        _rNR_END: item.ce048_nr_end,
        _rNM_BAIRRO: item.ce048_nm_bairro,
        _rNM_CIDADE: item.ce048_nm_cidade,
        _rSG_ESTADO: item.ce048_sg_estado,
        _rDS_COMPL: item.ce048_ds_compl,
        _rNR_CPFCNPJ: item.ce048_nr_cpfcnpj,
        _rNR_RG: item.ce048_nr_rg,
        _rNR_DDD: item.ce048_nr_dddtel,
        _rNR_TEL: item.ce048_nr_tel,
        _rCD_DEPTO: item.ce048_cd_depto,
        _rCD_CARGO: item.ce048_cd_cargo,
        _rVL_FIXO: this.unmaskCurrency(item.ce048_vl_fixo),
        _rPC_COMISS: item.ce048_pc_comiss_mdl
          .replace("%", "")
          .replace(",", "."),
        _rCD_OPESIS: usuario.base.us01_nr_usr,
        _rCD_BANCO: item.ce048_cd_banco,
        _rCD_AGENC: item.ce048_cd_agenc,
        _rCD_CNTCOR: item.ce048_cd_cntcor,
      };
      return ent;
    },
    async enviaDados(ent) {
      try {
        this.startLoader();
        await this.postSP("SP_CE360905000", ent);
        this.notification().success({mensagem:"Informações salvas"});
      } catch (err) {
        this.notification().error({erro: "Erro ao salvar informações"});
      } finally {
        this.stopLoader();
      }
    },
    async addNovoItem(item) {
      let ent = await this.criaObj(item);
      await this.enviaDados([ent]);
    },
    async editarItem(item) {
      let ent = await this.criaObj(item);
      await this.enviaDados([ent]);
    },
    deletarDaTabela(e) {
      e.forEach((f) => {
        let index = this.dados.findIndex((item) => item == f);
        if (index !== -1) {
          this.dados.splice(index, 1);
        }
      });
      this.abas.forEach((f, i) => {
        if (f.pesquisa.length == 0) {
          this.abas.splice(i, 1);
        }
      });
      this.keyTabelaWijmo++;
    },
    initializeGrid(flexgrid) {
      this.flexgrid = flexgrid;
    },
    escolheAba(aba) {
      this.abaEscolhida = aba;
    },
    alteraVendFerramentas(ret) {
      this.dados = this.dados.map((d) => {
        if (d.isSelected) {
          const situacMdl = ret.situac ? ret.situac : d.ce048_cd_situac;
          return {
            ...d,
            ce045_nm_fantas: ret.nomeLoja ? ret.nomeLoja : d.ce045_nm_fantas,
            ce048_nm_vended: ret.nomeVended
              ? ret.nomeVended
              : d.ce048_nm_vended,
            ce048_cd_situac: ret.situac ? ret.situac : d.ce048_cd_situac,
            ce048_vl_fixo: ret.vlFixo ? ret.vlFixo : d.ce048_vl_fixo,
            ce048_pc_comiss: ret.pcComiss ? ret.pcComiss : d.ce048_pc_comiss,
            ce048_cd_situac_mdl: situacMdl === 1 ? "Ativo" : "Inativo",
            ce048_pc_comiss_mdl: ret.pcComiss
              ? this.maskPorcentagem(ret.pcComiss)
              : this.maskPorcentagem(d.ce048_pc_comiss),
          };
        } else {
          return d;
        }
      });
    },
    async mountObj(obj) {
      try {
        this.startLoader();
        let vendedores = await this.chamaMulti("SP_CE360904001", obj);
        this.getVended(vendedores);
      } catch {
        this.notification().error({erro: "Erro ao trazer o produto"});
      } finally {
        this.stopLoader();
      }
    },
    async getVended(vendedDB) {
      try {
        this.startLoader();
        vendedDB = vendedDB ? vendedDB.database : null;
        if (vendedDB) {
          this.dados = vendedDB.map((p, i) => {
            return {
              ...p,
              isSelected: false,
              itemKey: i,
              ce048_vl_fixo: this.formatCurrency(p.ce048_vl_fixo).replace(
                "R$",
                "",
              ),
              ce048_cd_situac_mdl:
                p.ce048_cd_situac === 1 ? "Ativo" : "Inativo",
              ce048_pc_comiss_mdl:
                p.ce048_pc_comiss
                  .toFixed(2)
                  .toString()
                  .replace(".", ",") + "%",
              ce048_dt_incemp: this.maskData(p.ce048_dt_incemp),
            };
          });
        }
        const objAba = {
          nome_aba: `${this.$t("TelaLojas.pesquisa")} ${this.abas.length +
            1} - ${moment()
            .locale("pt-br")
            .format("l")} - ${this.$t("TelaLojas.quantidade")}: ${
            this.dados.length
          }`,
          pesquisa: vendedDB ? this.dados : [],
          remove: false,
          backup: vendedDB ? this.dados : [],
        };
        this.abas.push(objAba);
      } catch (e) {
        this.notification().error({erro: "Erro ao trazer os produtos"});
      } finally {
        this.stopLoader();
      }
    },
    erroBuscaPorFiltro() {
      this.notification().error({erro: "O filtro possui algum erro."});
    },
    salvaDadosTabela() {
      this.startLoader();
      let vendedores = [];
      this.dados.forEach(async (f) => {
        if (f.isSelected) {
          vendedores.push(await this.criaObj(f));
        }
      });
      setTimeout(async () => {
        if (vendedores.length !== 0) {
          await this.enviaDados(vendedores);
        } else {
          this.notification().warn({ mensagem: "Selecione os vendedores para salvar"});
        }
      }, 1000);
    },
    dadosConfirmados(dadosCorretos, dadosIncorretos, nomeArquivo) {
      dadosCorretos = dadosCorretos.map((m) => {
        return {
          ce048_nm_vended: m.Colaborador,
          ce045_nm_fantas: m.Loja,
          ce048_cd_situac_mdl: "Ativo",
          ce048_pc_comiss_mdl: m.Comissao + "%",
        };
      });
      if (dadosCorretos.length > 0) {
        let objAba = {
          nome_aba: `Dados corretos - ${nomeArquivo} - Quantidade: ${dadosCorretos.length}`,
          pesquisa: dadosCorretos,
          remove: false,
        };
        this.abas.push(objAba);
      }
      if (dadosIncorretos.length > 0) {
        this.notification().warn({ mensagem: `Um total de ${dadosIncorretos.length} encontrados`});
      }
    },
    aplicarBackup() {
      const backup = this.abas.find((aba) => aba.nome_aba === this.abaEscolhida)
        .backup;
      this.dados = backup;
    },
  },
};
